<template>
  <b-row>
    <b-col cols="5">
      <validation-observer v-slot="{ invalid }" ref="userForm">
        <form @submit.prevent="submitProfileData">
          <b-card title="Profile Info">
            <b-row>
              <b-col cols="6">
                <validation-provider vid="first_name" name="First Name" v-slot="context">
                  <b-form-group label="First Name">
                    <b-form-input
                      placeholder="e.g Brynhild"
                      v-model="user.first_name"
                      :state="getValidationState(context)"
                      :disabled="updateIsDisabled"
                      v-bind="context.ariaInput"
                    />

                    <b-form-invalid-feedback v-bind="context.ariaMsg">
                      {{ context.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="6">
                <validation-provider vid="last_name" name="Last Name" v-slot="context">
                  <b-form-group label="Last Name">
                    <b-form-input
                      placeholder="e.g Borgen"
                      v-model="user.last_name"
                      :state="getValidationState(context)"
                      :disabled="updateIsDisabled"
                      v-bind="context.ariaInput"
                    />

                    <b-form-invalid-feedback v-bind="context.ariaMsg">
                      {{ context.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="6">
                <validation-provider vid="email" name="Email" v-slot="context" rules="required|email">
                  <b-form-group label="Email">
                    <b-form-input
                      placeholder="e.g brynhild@gmail.com"
                      v-model="user.email"
                      :state="getValidationState(context)"
                      :disabled="updateIsDisabled"
                      v-bind="context.ariaInput"
                    />

                    <b-form-invalid-feedback v-bind="context.ariaMsg">
                      {{ context.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="6">
                <validation-provider vid="phone" name="Phone Number" v-slot="context">
                  <b-form-group label="Phone Number">
                    <b-form-input
                      v-model="user.phone"
                      :state="getValidationState(context)"
                      :disabled="updateIsDisabled"
                      v-bind="context.ariaInput"
                    />

                    <b-form-invalid-feedback v-bind="context.ariaMsg">
                      {{ context.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="6">
                <validation-provider vid="address" name="Address" v-slot="context">
                  <b-form-group label="Address">
                    <b-form-textarea
                      v-model="user.address"
                      :state="getValidationState(context)"
                      :disabled="updateIsDisabled"
                      v-bind="context.ariaInput"
                    />

                    <b-form-invalid-feedback v-bind="context.ariaMsg">
                      {{ context.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="3">
                <validation-provider vid="zip" name="Zip" v-slot="context">
                  <b-form-group label="Zip">
                    <b-form-input
                      v-model="user.zip"
                      :state="getValidationState(context)"
                      :disabled="updateIsDisabled"
                      v-bind="context.ariaInput"
                    />

                    <b-form-invalid-feedback v-bind="context.ariaMsg">
                      {{ context.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="3">
                <validation-provider vid="city" name="City" v-slot="context">
                  <b-form-group label="City">
                    <b-form-input
                      v-model="user.city"
                      :state="getValidationState(context)"
                      :disabled="updateIsDisabled"
                      v-bind="context.ariaInput"
                    />

                    <b-form-invalid-feedback v-bind="context.ariaMsg">
                      {{ context.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <hr />

            <b-row align-h="end">
              <b-col cols="auto">
                <b-button :disabled="updateIsDisabled || invalid || profileInfoLoading" variant="primary" type="submit">
                  Update
                  <b-spinner v-show="profileInfoLoading" class="ml-2" small label="Loading" />
                </b-button>
              </b-col>
            </b-row>
          </b-card>
        </form>
      </validation-observer>
      <br />

      <validation-observer v-if="profileIsLoggedIn && loggedInUser.id === profile.id" v-slot="{ invalid }" ref="passwordForm">
        <form @submit.prevent="submitPasswordData">
          <b-card title="Password">
            <b-row>
              <b-col cols="12">
                <validation-provider name="Old password" vid="old_password" v-slot="context" rules="required">
                  <b-form-group label="Old password">
                    <b-form-input
                      v-model="passwordForm.old_password"
                      :state="getValidationState(context)"
                      :disabled="updateIsDisabled"
                      v-bind="context.ariaInput"
                      type="password"
                    />

                    <b-form-invalid-feedback v-bind="context.ariaMsg">
                      {{ context.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col>
                <validation-provider name="Password" vid="password" v-slot="context" rules="required">
                  <b-form-group label="Password">
                    <b-form-input
                      v-model="passwordForm.password"
                      :state="getValidationState(context)"
                      :disabled="updateIsDisabled"
                      v-bind="context.ariaInput"
                      type="password"
                    />

                    <b-form-invalid-feedback v-bind="context.ariaMsg">
                      {{ context.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col>
                <validation-provider name="Password Confirmation" vid="password_confirmation" v-slot="context" rules="required">
                  <b-form-group label="Confirm Password">
                    <b-form-input
                      v-model="passwordForm.password_confirmation"
                      :state="getValidationState(context)"
                      :disabled="updateIsDisabled"
                      v-bind="context.ariaInput"
                      type="password"
                    />

                    <b-form-invalid-feedback v-bind="context.ariaMsg">
                      {{ context.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <hr />

            <b-row align-h="end">
              <b-col cols="auto">
                <b-button :disabled="updateIsDisabled || invalid || passwordLoading" variant="primary" type="submit">
                  Update
                  <b-spinner v-show="passwordLoading" class="ml-2" small label="Loading" />
                </b-button>
              </b-col>
            </b-row>
          </b-card>
        </form>
      </validation-observer>

      <validation-observer v-else v-slot="{ invalid }">
        <b-card title="Reset Password">
          <form @submit.prevent="resetPassword">
            <validation-provider name="Email Address" v-slot="context" rules="required|email">
              <b-form-group label="Enter email">
                <b-form-input
                  v-model="user.email"
                  placeholder="e.g user@example.com"
                  :state="getValidationState(context)"
                  readonly
                  trim
                />
              </b-form-group>
            </validation-provider>

            <b-row align-h="end">
              <b-col cols="auto">
                <b-button type="submit" :disabled="invalid || resetLoading" variant="primary">
                  Reset password
                  <b-spinner v-show="resetLoading" class="ml-2" small label="Loading" />
                </b-button>
              </b-col>
            </b-row>
          </form>
        </b-card>
      </validation-observer>
    </b-col>
  </b-row>
</template>

<script>
import {account, passwords, users} from '@/api'
import { mapGetters } from 'vuex'
import { getValidationState } from '../page-helpers'

export default {
  name: 'UserSettings',
  props: { profile: { type: Object } },
  data() {
    return {
      user: this.profile,
      passwordForm: {
        old_password: '',
        password: '',
        password_confirmation: ''
      },
      profileInfoLoading: false,
      passwordLoading: false,
      resetLoading: false
    }
  },
  watch: {
    profile(profile) {
      this.user = profile
    },
  },
  computed: {
    ...mapGetters({ loggedInUser: 'auth/user' }),
    profileIsLoggedIn() {
      return true
    },
    userProfileIsAdmin() {
      const roles = ['Super Admin', 'Dashboard User']

      return this.user.roles?.some(role => roles.includes(role))
    },
    updateIsDisabled() {
      return this.userProfileIsAdmin && !this.profileIsLoggedIn
    },
  },
  methods: {
    getValidationState,
    async submitProfileData() {
      this.profileInfoLoading = true;

      const form = {
        first_name: this.user.first_name,
        last_name: this.user.last_name,
        email: this.user.email,
        phone: this.user.phone,
        address: this.user.address,
        zip: this.user.zip,
        city: this.user.city,
      };

      try {
        if (this.loggedInUser.id === this.profile.id) {
          await account.update(form)
        } else {
          await users.update(this.profile.id, form)
        }

        this.$bvToast.toast(`Profile data was successfully updated.`, {
          title: 'Profile updated',
          variant: 'primary',
        })
      } catch (error) {
        this.$refs.userForm.setErrors(error.errors)
      }

      this.profileInfoLoading = false;
    },
    submitPasswordData() {
      if (this.passwordForm.password !== this.passwordForm.password_confirmation) {
        this.$bvToast.toast(`The password does not match`, {
          title: 'Something wrong',
          variant: 'danger'
        })
        return
      }

      this.passwordLoading = true;

      account.update(this.passwordForm)
        .then(() => {
          this.passwordForm = {
            old_password: '',
            password: '',
            password_confirmation: ''
          };

          this.$bvToast.toast(`Password updated.`, {
            title: 'Password updated',
            variant: 'primary',
          })
        })
        .catch((error) => this.$refs.passwordForm.setErrors(error.errors))
        .finally(() => this.passwordLoading = false)
    },

    async resetPassword() {
      this.resetLoading = true;

      await passwords.reset(this.user.email)

      this.$bvToast.toast(`Reset password email has been sent.`, {
        title: 'Reset password',
        variant: 'primary',
      })

      this.resetLoading = false;
    }
  },
}
</script>
